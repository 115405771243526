//各个页面配置
const pagesCg = [
  {
    //工程机械设备险
    pageType: 1,
    //底部上传按钮
    bottomUploadShow: true,
    //项目名称字典名
    dictProjectName: "projectName",
    //显示保单文件块
    showPolicyUpload: true,
    //显示标的块
    showSubject: true,
    //显示保障计划块
    showPlan: true,
    //显示受益人
    showBeneficiary: {
      show: true,
      disShowItem: ["contactEmail"],
    },
    //列表
    table: {
      show: true,
      disShowItem: ["insuredCategoryName", "projectName", "insuranceOfDate"],
    },
    //搜索
    search: {
      show: true,
      disShowItem: [],
    },
    //基本信息
    baseInfo: {
      show: true,
      disShowItem: [
        "insuredCategory",
        "totalPremium",
        "province",
        "city",
        "sourceChannel",
      ],
    },
    //投保人
    policyHolder: {
      show: true,
      disShowItem: ["detailAddress", "contactEmail"],
    },
    //被保人
    policyInsured: {
      show: true,
      disShowItem: ["detailAddress", "sex", "birthday", "contactEmail"],
    },
    //标的
    policySubject: {
      show: true,
      disShowItem: ["engineNo", "itemNo", "agency", "address", "workTime"],
    },
  },
  {
    //车险
    pageType: 2,
    bottomUploadShow: true,
    dictProjectName: "CXInsProjectName",
    showPolicyUpload: false,
    //显示标的块
    showSubject: true,
    //显示保障计划块 - 绩效管理开放
    showPlan: false,
    // showPlan: true,
    //显示保障计划块
    showNewPlan: true,
    //显示受益人
    showBeneficiary: {
      show: true,
      disShowItem: [],
    },
    //列表
    table: {
      show: true,
      disShowItem: ["insuredCategoryName"],
    },
    //搜索
    search: {
      show: true,
      disShowItem: [],
    },
    //基本信息
    baseInfo: {
      show: true,
      disShowItem: ["insuredCategoryName", "applicantMode", "applicantMode"],
      // 'totalPremium',
    },
    //投保人
    policyHolder: {
      show: true,
      disShowItem: [],
    },
    //被保人
    policyInsured: {
      show: true,
      disShowItem: [],
    },
    //标的
    policySubject: {
      show: true,
      disShowItem: [
        "deviceType",
        "itemNo",
        "yearlyDepreciation",
        "agency",
        "origin",
        "address",
        "workTime",
        "vehicleAccredit",
      ],
    },
  },
  {
    //非设备类财产险
    pageType: 3,
    bottomUploadShow: false,
    dictProjectName: "FCXInsProjectName",
    showPolicyUpload: false,
    //显示保障计划块
    showNewPlan: true,
    //显示标的块
    showSubject: false,
    //显示保障计划块 - 绩效管理开放
    showPlan: false,
    // showPlan: true,
    //显示受益人
    showBeneficiary: {
      show: false,
      disShowItem: [],
    },
    //列表
    table: {
      show: true,
      disShowItem: [
        "frameNo",
        "totalPremium",
        "insuranceTypeName",
        "projectName",
        "insuranceOfDate",
        "sourceChannel",
      ],
    },
    //搜索
    search: {
      show: true,
      disShowItem: [],
    },
    //基本信息
    baseInfo: {
      show: true,
      disShowItem: [
        "insuranceOfDate",
        "policyNo",
        "applicantStore",
        "applicantMode",
        "insuredCategoryName",
      ],
      // , 'totalPremium'
    },
    //投保人
    policyHolder: {
      show: true,
      disShowItem: [
        "birthday",
        "sex",
        "detailAddress",
        "cardStart",
        "contactName",
        "contactEmail",
        "contactPhone",
      ],
    },
    //被保人
    policyInsured: {
      show: true,
      disShowItem: [
        "birthday",
        "sex",
        "detailAddress",
        "cardStart",
        "contactName",
        "contactEmail",
        "contactPhone",
      ],
    },
    //标的
    policySubject: {
      show: true,
      disShowItem: ["vehicleAccredit"],
    },
    //批单类型选择配置
    changeCreateCorrectionIndex: [1, 2, 3],
    changeItem: [
      {
        show: true,
        shadowColor: "#D2E6FFFF",
        backgroundImageUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/b1.png"),
        optionIconUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/i1.png"),
        optionTitle: "人员信息变更",
        content: "更改被保险人、投保人信息",
        backgroundColor: "#F6FAFFFF",
        iconBgColor: "#3ABEE5FF",
        type: "1",
      },
      {
        show: false,
      },
      {
        show: true,
        shadowColor: "#FFEBDBFF",
        backgroundImageUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/b3.png"),
        optionIconUrl: require("@/views/CorrectionSheetManagement/pages/CreateCorrection/static/i3.png"),
        optionTitle: "保单文件",
        content: "更改上传的保单相关的文件",
        backgroundColor: "#FFFAF6FF",
        iconBgColor: "#FFADC1FF",
        type: "3",
      },
    ],
  },
];

export function getPageConfig(type = 1) {
  return (
    pagesCg.find((item) => {
      return item.pageType == type;
    }) || new Error("无法找到对应类型！")
  );
}
